import React from "react";
import Helmet from "react-helmet";
import sortBy from "lodash/sortBy";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import Card from "../components/Card/Card";
import config from "../../data/SiteConfig";

export default class TagTemplate extends React.Component {
  render() {
    const {
      data: {
        contentfulTag,
        allContentfulTag: { group }
      },
      location
    } = this.props;

    const posts = sortBy(contentfulTag.post, "updatedAt").reverse();

    return (
      <Layout location={location}>
        <Helmet title={`${contentfulTag.title} | ${config.siteTitle}`} />
        <section className="section">
          <div className="container">
            <h1 className="title is-size-3 has-text-centered">
              {contentfulTag.title}
            </h1>
            <section className="section is-paddingless-horizontal">
              <div className="columns is-multiline is-centered">
                <div className="column is-9-widescreen is-12-tablet">
                  <div className="columns is-multiline">
                    {posts.map(post => (
                      <div className="column is-12" key={post.id}>
                        <Card
                          horizontal
                          titleSize="is-size-5"
                          contentHeight={280}
                          slug={post.slug}
                          title={post.title}
                          description={post.description}
                          cover={post.cover}
                          category={post.category.title}
                          updatedAt={post.updatedAt}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column is-3-widescreen is-12-tablet">
                  <h2 className="title is-size-5">Explore Other Tags</h2>
                  <div className="field is-grouped is-grouped-multiline">
                    {group.map(member => (
                      <div key={member.fieldValue} className="control">
                        <div className="tags">
                          <Link
                            to={`/tag/${member.edges[0].node.slug}/`}
                            className="tag is-capitalized"
                          >
                            {member.fieldValue}
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const query = graphql`
  query($slug: String!) {
    contentfulTag(slug: { eq: $slug }) {
      title
      post {
        id
        slug
        title
        description
        category {
          title
        }
        cover {
          gatsbyImageData(
            width: 800
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        updatedAt
      }
    }
    allContentfulTag {
      group(field: title) {
        fieldValue
        edges {
          node {
            slug
          }
        }
      }
    }
  }
`;
